
          @use "sass:math";
          @import "~@/assets/scss/variables.scss";
          @import "~@/assets/scss/mixins/misc.scss";
        

.employee-replacement-view {
  &__block {
    display: flex;
  }
}
